import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
  AggregateTask,
  Mutation,
  MutationDeleteTaskArgs,
  MutationUpdateTaskArgs,
  Query,
  QueryAggregateTasksArgs,
  QueryFindManyTasksArgs,
  Task,
  TaskCreateManyInput,
} from '../types';
import { RcApiService } from './rc-api.service';

const FIND_MANY_TASKS = gql`
  query FindManyTasks(
    $where: TaskWhereInput
    $orderBy: [TaskOrderByWithRelationInput!]
    $cursor: TaskWhereUniqueInput
    $take: Int
    $skip: Int
    $distinct: [TaskScalarFieldEnum!]
  ) {
    findManyTasks(
      where: $where
      orderBy: $orderBy
      cursor: $cursor
      take: $take
      skip: $skip
      distinct: $distinct
    ) {
      id
      title
      description
      categories
      details
      resources
      status
      createdAt
      dueAt
      updatedAt
      completedAt
      assignee
      unit {
        id
        name
        property {
          address
        }
        tenants {
          name
        }
      }
    }
  }
`;

const AGGREGATE_TASKS = gql`
  query AggregateTask(
    $where: TaskWhereInput
    $orderBy: [TaskOrderByWithRelationInput!]
    $cursor: TaskWhereUniqueInput
    $take: Int
    $skip: Int
    $_count: TaskCountAggregateInput
    $_min: TaskMinAggregateInput
    $_max: TaskMaxAggregateInput
  ) {
    aggregateTasks(
      where: $where
      orderBy: $orderBy
      cursor: $cursor
      take: $take
      skip: $skip
      _count: $_count
      _min: $_min
      _max: $_max
    ) {
      _count {
        _all
      }
    }
  }
`;

const TASK_REFETCH_LIST = [FIND_MANY_TASKS, AGGREGATE_TASKS];

@Injectable()
export class TaskService {
  constructor(private rcApiService: RcApiService) {}

  public findManyTasks(args?: QueryFindManyTasksArgs): Observable<Task[]> {
    return this.rcApiService.apollo
      .watchQuery<Query>({
        query: FIND_MANY_TASKS,
        variables: args,
        refetchWritePolicy: 'overwrite',
        pollInterval: 5000,
      })
      .valueChanges.pipe(map((result) => result.data.findManyTasks));
  }

  public getTaskById(id: string): Observable<Task> {
    return this.rcApiService.apollo
      .query<Query>({
        query: gql`
          query getTaskById($id: ID!) {
            getTaskById(id: $id) {
              id
              title
              description
              categories
              details
              resources
              status
              createdAt
              dueAt
              updatedAt
              completedAt
              assignee
              unit {
                id
                name
                property {
                  address
                }
                tenants {
                  name
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      })
      .pipe(map((result) => result.data.getTaskById));
  }

  public createTasks(data: TaskCreateManyInput[]): Observable<Task[]> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation CreateTasks($data: [TaskCreateManyInput!]!) {
            createTasks(data: $data) {
              id
              title
              description
              categories
              details
              resources
              status
              createdAt
              dueAt
              updatedAt
              completedAt
              assignee
              unit {
                id
                name
                property {
                  address
                }
                tenants {
                  name
                }
              }
            }
          }
        `,
        variables: {
          data,
        },
        refetchQueries: TASK_REFETCH_LIST,
        awaitRefetchQueries: true,
      })
      .pipe(map((result) => result.data!.createTasks));
  }

  // TODO: Look into rectifying _count, _min, _max, etc. optional fields and the query definition
  public aggregateTasks(
    args: QueryAggregateTasksArgs,
  ): Observable<AggregateTask> {
    return this.rcApiService.apollo
      .watchQuery<Query>({
        query: AGGREGATE_TASKS,
        variables: args,
        pollInterval: 5000,
      })
      .valueChanges.pipe(map((result) => result.data.aggregateTasks));
  }

  public updateTask(args: MutationUpdateTaskArgs): Observable<Task> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation UpdateTask(
            $data: TaskUpdateInput!
            $where: TaskWhereUniqueInput!
          ) {
            updateTask(data: $data, where: $where) {
              id
              title
              description
              categories
              details
              resources
              status
              createdAt
              dueAt
              updatedAt
              completedAt
              assignee
              unit {
                id
                name
                property {
                  address
                }
                tenants {
                  name
                }
              }
            }
          }
        `,
        variables: args,
        refetchQueries: TASK_REFETCH_LIST,
        awaitRefetchQueries: true,
      })
      .pipe(map((result) => result.data!.updateTask));
  }

  public deleteTask(args: MutationDeleteTaskArgs): Observable<void> {
    return this.rcApiService.apollo
      .mutate<Mutation>({
        mutation: gql`
          mutation DeleteTask($where: TaskWhereUniqueInput!) {
            deleteTask(where: $where) {
              id
            }
          }
        `,
        variables: args,
        refetchQueries: TASK_REFETCH_LIST,
        awaitRefetchQueries: true,
      })
      .pipe(
        map(() => {
          return;
        }),
      );
  }
}
